
.LoginPage {
  clear: right;

  .Page__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  h1 {
    color: white;
    font-size: 5.8rem;
    margin-bottom: 60px;
  }

  .box {
    position: relative;
    flex: 0 1 333px;
    width: 400px;
    padding: 35px 30px 90px;
  }

  &.connectByGoogle {
    .box {
      padding-bottom: 30px;
    }
  }

  &__Loading {
    width: 100%;
    text-align: center;
  }

  @media (min-width: $break-small) {

  }

  @media (max-width: $break-smaller) {
    .Page__Container {
      display: block;
    }

    h1 {
      font-size: 3.2rem;
      margin-bottom: 10px;
      margin-top: 0;
      padding-top: 15px;
    }

    .box {
      width: 100%;
      flex-basis: 300px;
    }
  }

  @media (max-width: $break-tiny) {
    .Page__Container {
      //display: block;
    }

    h1 {
      font-size: 2rem;
      padding-right: 60px;
    }

    .box {
      padding: 25px 15px 90px;
    }

    .Button--Back {
      font-size: 1.3rem;
    }
  }


  input[type=text], input[type=email], input[type=password] {
    width: 100%;
    height: 38px;
    line-height: 28px;
    display: block;
  }

  .Button {
    width: 100%;
    margin: 20px 0 15px;
    display: block;

    &--back {
      width: auto;
      margin-bottom: 0;
      position: absolute;
      bottom: 30px;
      left: 30px;
/*
      &--onConnect {
        position: relative;
        left: unset;
        bottom: unset;
        margin-top: 30px;
      }*/
    }
  }

  .reset {
    cursor: pointer;
    margin-bottom: 0;
  }

  .GoogleButton {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: calc(100% - 60px);

    &__Button {
      position: relative;
      display: inline-block;
      background: white;
      color: #757575;
      width: 100%;
      border-radius: 1px;
      border: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      white-space: nowrap;
      font-family: JosefinSans, arial, sans-serif;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: center;
      transition: background-color .218s, border-color .218s, box-shadow .218s;
      outline: none;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 3px 2px rgba($color-blue, 0.3);

      }

      span.label {
        letter-spacing: .21px;
        vertical-align: middle;
        line-height: 38px;
      }

      span.icon {
        position: absolute;
        left: 0;
        top: 0;
        background: url('../assets/g-normal.png') transparent 5px 50% no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 38px;
        height: 38px;
      }
    }
  }

  .VerifyEmail {
    text-align: center;
  }

  &__Register {
    &__Headline {

      &__General {
        span {
          color: $color-blue;
          font-style: italic;
          font-weight: bold;
        }
      }

      &__Step1 {
        font-size: 1.8rem;
        display: inline-block;
        margin-top: 5px;
      }

      &__Step2 {
        font-size: 1.8rem;
      }

      &__Step2Info {
        margin-bottom: 20px !important;

        &__More {
          color: $color-blue;
          cursor: pointer;
          font-style: normal !important;
          font-weight: normal !important;
        }
      }
    }

    &__Agreement {
      margin: 25px 0;

      p {
        font-size: 1.8rem;
      }

      label {
        display: flex;
        flex-direction: row;
        gap: 10px;

        input {
          margin: 5px 0px;
        }
      }

      div {
        padding-left: 23px;
        font-size: 1.3rem;
      }
    }
    
    &__Skip {
      label {
        cursor: pointer;
      }
    }
  }
}