.AccountBox {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  &__Info {

    &__Email {
      margin-bottom: 15px;
    }

    &__Country {
      margin-top: 7px;
      line-height: 24px;

      * {
        vertical-align: middle;
      }

      img {
        height: 24px;
        margin-right: 7px;
      }
    }
  }

  label,
  .label {
    color: $color-grey;
    display: inline-block;
    margin-right: 7px;
  }
}
