.OrderDonePage {

  .Page__Container .box {
    width: unset;
    min-width: 40%;
    max-width: 650px;
    height: auto;

    @media (max-width: $break-small) {
      min-width: 80%;
    }
  }

  .Result {
    text-align: center;

    &__Status {
      margin-bottom: 45px;
      line-height: 1.5;

      span {
        vertical-align: middle;
      }

      &__Reason {
        font-style: italic;
      }

      .IcoDone, .IcoCross {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      .IcoDone {
        width: 22px;
        height: 16px;
      }
      .IcoCross {
        width: 16px;
        height: 16px;
      }
    }
  }
}
