.Button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  appearance: none;
  //border: 1px solid $color-grey;
  border: none;
  background-color: $color-blue;
  outline: none;
  cursor: pointer;
  height: 38px;
  line-height: 22px;
  padding: 9px 16px 7px;

  font-size: 1.3rem;
  letter-spacing: 0.4px;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  transition: box-shadow ease-in $a-speed, transform ease-in $a-speed;

  &:hover {
    //box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    //box-shadow: 0 1px 3px 0 rgba($color-blue, 0.2), 0 2px 1px -1px rgba($color-blue, 0.12), 0 1px 1px 0 rgba($color-blue, 0.14);
    box-shadow: 0 0 3px 2px rgba($color-blue, 0.3);
  }

  &:active,
  &:active:focus {
    transform: scale3d(0.98, 0.98, 1);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }

  &:focus {
    box-shadow: 0 1px 5px 0 rgba($color-blue, 0.2), 0 3px 1px -2px rgba($color-blue, 0.12), 0 2px 2px 0 rgba($color-blue, 0.14);
  }

  // Disabled state
  &[disabled],
  &.disabled {
    cursor: not-allowed;
    opacity: .6;
    color: var(--color-white);
    background: $color-grey;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }

  &--close {
    padding: 0px;
    background-color: $color-blue;
    color: white;
    text-align: center;
    border-radius: 16px;
    height: 24px;
    width: 24px;
    position: absolute;
    top: -12px;
    right: -12px;
  }

  &--rounded {
    border-radius: 4px;
  }

  &--white {
    background-color: white;
  }

  &--transparent {
    background: transparent;
    border: 1px solid transparent;
  }
  &--transparent:hover {
   // border: 1px solid $color-grey;
  }

  &--success {
    background-color: $color-green;
    color: white;
  }

  &--pink {
    background-color: $color-pink;
    color: white;
  }

  &--small {
    font-size: 1.2rem;
    letter-spacing: 0.24px;
    height: 28px;
    line-height: 18px;
    padding: 6px 12px;
  }

  &--large {
    font-size: 1.4rem;
    letter-spacing: 0.4px;
    //line-height: 24px;
    height: 44px;
    padding: 12px 16px;
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;

    /*
    svg {
      width: 24px;
      height: 24px;

      * {
        fill: var(--color-black);
      }
    }
    */

    img {
      //filter: invert(92%) sepia(79%) saturate(0%) hue-rotate(201deg) brightness(111%) contrast(102%);  //cerna > bila
    }

    &--right {
      right: 16px;
    }

    &--left {
      left: 16px;
    }
  }

  &--small &__icon {
    width: 18px;
    height: 18px;
    top: 6px;
/*
    svg {
      width: 20px;
      height: 20px;
    }*/
  }

  &--large &__icon {
    top: 12px;

    &--right {
      right: 16px;
    }

    &--left {
      left: 16px;
    }
  }
/*
  &[disabled] &__icon svg * {
    fill: var(--color-white);
  }

  &--onDark[disabled] &__icon svg * {
    fill: var(--color-gray);
  }*/

  &--rightIcon {
    padding-right: 46px;
  }

  &--small.Button--rightIcon {
    padding-right: 44px;
  }

  &--large.Button--rightIcon {
    padding-right: 48px;
  }

  &--leftIcon {
    padding-left: 46px;
  }

  &--small.Button--leftIcon {
    padding-left: 44px;
  }

  &--large.Button--leftIcon {
    padding-left: 48px;
  }
}

.CloseButton {
  appearance: none;
  padding: 0px;
  padding-bottom: 3px;
  background-color: transparent;
  color: $color-blue;
  border: 1px solid $color-blue;
  border-radius: 16px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: center;

  .IcoCross {
    width: 10px;
    height: 10px;
    margin-bottom: 1px;
  }

  &:hover,
  &:active {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    .IcoCross {
      width: 12px;
      height: 12px;
      margin-bottom: 0px;
    }
  }

  &:focus {
    box-shadow: 0 1px 5px 0 rgba($color-blue, 0.2), 0 3px 1px -2px rgba($color-blue, 0.12), 0 2px 2px 0 rgba($color-blue, 0.14);
  }
}

.IcoButton {
  cursor: pointer;
  vertical-align: middle;
  transition: filter ease-in $a-speed, transform ease-in $a-speed;

  &:link {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    svg {
      -webkit-filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.8));
      filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.8));
    }
  }
  &:active,
  &:active:focus {
    text-decoration: none;
    transform: scale3d(0.98, 0.98, 1);
    svg {
      -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.8));
      filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.8));
    }
  }
  &:focus {
    svg {
      -webkit-filter: drop-shadow(1px 1px 3px rgba($color-blue, 0.8));
      filter: drop-shadow(1px 1px 3px rgba($color-blue, 0.8));
    }
  }

  &--edit {
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
      stroke: none;
      fill: white;

      -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.8));
      filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0.8));
    }
  }
}

.ButtonLink {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #0287cf;
  vertical-align: middle;

  &:hover {
    text-decoration: underline;
  }
}