
.Overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__BackGround {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
  }

  &__Panel {
    position: relative;
    z-index: 2;
    padding: 7rem;
    background-color: white;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    transition: background-color ease-in $a-speed350;

    //min-width: 50%;
    min-height: 50%;
    max-height: 85%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }

  &--minimized {
    pointer-events: none;

    .Overlay__Panel {
      background-color: rgba(255, 255, 255, 0.1);
      transition: background-color ease-out $a-speed350;
      //width: 1px !important;
      //height: 1px !important;
    }
  }

  .CloseButton {
    .IcoCross {
      fill: #1f79e1;
    }
  }
}
