
.UserForm {
  label {
    display: block;
    margin-bottom: 2px;
  }

  input, select {
    width: 100%;
  }

  .row {
    display: grid;
    gap: 3%;
    margin-bottom: 2%;

    @media (max-width: $break-small) {
      margin-bottom: 20px;
    }
  }
  .row.last {
    margin-bottom: 0;

    @media (max-width: $break-small) {
      margin-bottom: 20px;
    }

    div {
      margin-bottom: 15px;

      &.country {
        margin-bottom: 0px;
      }
    }
  }

  fieldset {
    border: 1px solid $color-grey;
    margin-top: 15px;
    padding-bottom: 25px;
  }
  legend {
    color: $color-grey;
    padding: 0 7px;
  }

  .nationality, .country {
    img {
      height: 30px;
      margin: 4px;
    }
    select {
      float: right;
      width: calc(100% - 60px);
      vertical-align: top;
    }
  }

  .row div {
    position: relative
  }
  p.error {
    position: absolute;
    margin: 0;
    right: 0;
    font-size: 1.3rem;
  }

  .submit {
    align-self: end;
    display: flex;
    flex-direction: row;
    gap: 15px;

    input, button {
      flex: 1;
    }
  }

  &__LastFlexspace {
    margin-top: 25px;
    font-size: 1.3rem;
    font-style: italic;
    //align-self: end;
    // padding-bottom: 55px;
  }

  @media (min-width: $break-medium) {
    .row {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }
    fieldset {
    }
  }
}
