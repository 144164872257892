
.UserEditPage {

  .ProfileSection {
    position: relative;
    margin-bottom: 15px;
  }

  .EditSection {
    .box {
      padding: 30px;
    }
  }
}
