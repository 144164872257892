// Fonts
$font-default: JosefinSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-native: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// Colors
$color-blue: #22B1FF;
$color-black: rgb(7, 7, 15);
$color-grey-light: rgb(183, 189, 202);
$color-grey: rgb(143, 147, 157);
$color-grey-darker: rgb(98, 101, 108);
$color-grey-dark: rgb(48, 48, 52);
$color-bg-grey: rgb(237, 238, 241);
$color-bg-grey-little-darker: rgb(218, 219, 223);
$color-bg-grey-light: rgb(241, 243, 249);
$color-bg-grey-hover: rgb(222, 225, 231);
$color-bg-ipnut: rgba(255, 255, 255, 0.7);
$color-green: #139b13;
$color-error: #bc3535;
$color-red: #bc3535;
$color-pink: #e5155d;

//blue marigns
//border-right-color: #afd6eb;
//border-left-color: rgba(21,120,174,0.7);
//box-shadow: #1578aeab 1px 1px 1px;

// Animations
$a-speed: 200ms;
$a-speed350: 350ms;
$a-speed500: 500ms;
$a-speed1: 1000ms;
$animation-timing: ease-in-out;
$animation-duration: .24s;

// Layout
$layout-width-regular: 1088px;
$layout-width-smaller: 1028px;

// Breakpoints
$b-regular: $layout-width-regular;
$b-small: 768px;
$b-small-max: 767px;
$b-smallest: 600px;

$break-large: 1506px;
$break-notebook: 1360px;
$break-medium: 1100px;
$break-small: 830px;
$break-smaller: 670px;
$break-smallest: 480px;
$break-tiny: 320px;