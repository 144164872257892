
.DashboardPage {

  .lineWithIco {
    line-height: 20px;
    .ico {
      vertical-align: middle;
    }

    svg {
      cursor: pointer;
      fill: transparent;
      stroke: #1f79e1;
      stroke-width: 2;
      height: 20px;
      width: 20px;
    }
  }

  .ProfileSection {
    position: relative;
    margin-bottom: 15px;
  }

  .PassportSection {
    position: relative;
    width: 102%;
    left: -1%;
    margin-bottom: 15px;

    .box {
      padding: 30px;

      @media (max-width: $break-small) {
        padding: 20px;
      }

      > div {
        padding-bottom: 15px;

        &:last-of-type {
          padding-bottom: 0px;
        }
      }
    }

    h2 {
      margin: 7px 0 15px;
    }

    h4 {
      margin: 0px 0px 22px;
    }
  }

  .PartnersSection {
    margin-bottom: 20px;
  }

  .ConnectMoreSection {
    margin-bottom: 30px;
    .box {
      background-color: $color-bg-grey-little-darker;
      padding: 15px 30px;
      font-size: 1.4rem;
      display: flex;

      @media (max-width: $break-small) {
        padding: 15px 20px;
      }
    }

    .lineWithIco {
      flex-basis: 60%;

      .text {
        float: left;
      }
      .image {
        height: 50px;
        margin-top: 7px;
        margin-left: 15px;
      }
    }

    .buttonCont {
      flex-basis: 40%;
      align-self: center;
      text-align: right;
    }
  }
}
