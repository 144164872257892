
.BarMessage {
  z-index: 11;

  .Overlay__BackGround {
    pointer-events: none;
  }

  .Overlay__Panel {
    min-width: auto;
    min-height: auto;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
  }

  &__Message {
    padding: 2rem;
  }

  .CloseButton {
    display: none;
  }

  svg {
    width: auto;
    margin-right: 1rem;
  }
  .IcoDone {
    fill: white;
    height: 1.2rem;
  }
  .IcoError {
    height: 1.8rem;
    vertical-align: bottom;
  }

  &--success {
    .Overlay__Panel {
      background-color: $color-green;
      color: white;
    }
  }
  &--error {
    .Overlay__Panel {
      background-color: $color-red;
      color: white;
    }
  }

  &--success.Overlay--minimized,
  &--error.Overlay--minimized {
    .Overlay__Panel {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
