.LoginToken {
  margin-right: 15px;
  line-height: 38px;

  input {
    width: 60px;
  }

  .Button {
    width: 30px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    .IcoDone {
      fill: white;
      width: 16px;
    }
  }
}