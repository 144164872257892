@use 'sass:math';

.PassportDetail {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: $color-bg-grey-light;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .Container {
    position: relative;
    width: 100%;
    height: 100%;
    //background-color: $color-bg-grey-light;
    //box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    //border: 1px solid #ccc;
    border-left-color: white;
    border-top-color: white;
  }

  .CloseButton {
    z-index: 5;
  }

  @media (min-width: $break-small) {
    .Container {
      width: calc(100% - 60px);
      height: calc(100% - 60px);
    }
  }
  @media (min-width: $break-medium) {
    .Container {
      width: $layout-width-smaller + 40px;
      height: $layout-width-smaller * math.div(9, 16);
    }
  }
  @media (min-width: $break-notebook) {
    .Container {
      width: $b-regular + 60px;
      height: $b-regular * math.div(9, 16);
    }
  }
  @media (max-width: $break-small) {
    display: block;
    overflow-y: auto;

    .Container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: center;
    }
  }

  &__Image {
    width: 40%;
    height: 90%;
    position: absolute;
    left: 5%;
    top: 5%;
    z-index: 2;

    img {
      width: 100%;
    }

    @media (max-width: $break-small) {
      width: auto;
      height: auto;
      flex: 1;
      overflow: auto;

      left: unset;
      top: unset;
      position: static;
      padding: 10px;
    }
  }

  &__Properties {
    position: absolute;
    padding: 5% 5% 5% 7%;
    width: 55%;
    height: 70%;
    right: 5%;
    top: 15%;
    z-index: 1;
    background-color: white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .16), 0 1px 18px 0 rgba(0, 0, 0, .08), 0 6px 10px 0 rgba(0, 0, 0, .10);
    text-align: left;
    font-size: 1.7rem;

    @media (min-width: $break-small) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @media (max-width: $break-small) {
      width: 90%;
      height: auto;
      position: static;

      top: unset;
      left: unset;
      right: unset;
      margin-top: 20px;
    }

    &__Passport {
      margin-bottom: 10px;

      @media (min-width: $break-notebook) {
        line-height: 47px;
      }

      &__Label {
        font-weight: normal;
        font-size: 2.8rem;
        color: rgb(48, 48, 52);

        @media (max-width: $break-small) {
          font-size: 2.3rem;
          display: inline-block;
        }
        @media (min-width: $break-notebook) {
          display: inline-block;
        }
        @media (max-width: $break-tiny) {
          font-size: 2rem;
          display: inline-block;
          margin-right: 10px;
        }
      }

      &__Number {
        font-weight: bold;
        font-size: 3.5rem;

        @media (max-width: $break-small) {
          font-size: 2.8rem;
        }
        @media (max-width: $break-small) {
          display: inline-block;
          margin-left: 15px;
        }
        @media (min-width: $break-notebook) {

        }
        @media (max-width: $break-tiny) {
          font-size: 2.5rem;
          display: inline-block;
        }
      }
    }

    &__Valid {
      margin-bottom: 30px;
      color: rgb(48, 48, 52);
    }

    &__Owner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__Label {
        flex-basis: 100%;
        font-weight: normal;
        margin-bottom: 10px;
        color: rgb(48, 48, 52);

        @media (max-width: $break-tiny) {
          margin-bottom: 5px;
        }
      }

      &__Values {
        flex: 1 1 70%;
        font-weight: bold;

        &__Firstname {
          font-size: 2rem;
          margin-bottom: 0px;
          @media (min-width: $break-notebook) {
            display: inline-block;
          }
        }
        &__Lastname {
          font-size: 2rem;
          margin-bottom: 10px;
          @media (min-width: $break-notebook) {
            display: inline-block;
            margin-left: 10px;
          }
        }

        &__Nationality {
          margin-top: 10px;
          margin-bottom: 0;
          > * {
            vertical-align: middle;
          }
          img {
            height: 20px;
            margin-right: 10px;
          }
        }
      }
      &__Avatar {
        flex: 1 1 30%;

        .Avatar {
          width: auto;
          height: auto;
          float: none;
          margin: 0;
        }
      }

    }
  }
}