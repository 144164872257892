
.PassportConnector {
  &__InnerPadding {
    padding: 30px;

    @media (max-width: $break-small) {
      padding: 30px 0;
    }
  }

  .Overlay__Panel {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color ease-out $a-speed, height ease-out $a-speed500;
    padding: 3rem;
    min-height: 10px;
    height: 480px;
    text-align: left;
    border: 10px solid $color-pink;

    @media (max-width: $break-small) {
      padding: 30px 20px;
    }
  }

  &.Overlay--minimized {
    .Overlay__Panel {
      background-color: $color-pink;
      transition: background-color ease-in $a-speed, height ease-in $a-speed500;
      min-height: 10px;
      height: 10px;
      overflow: hidden;
    }
  }

  form {
    position: relative;

    p {
      white-space: nowrap;
    }
  }

  label,
  .label {
    color: $color-grey;
    display: inline-block;
    margin-bottom: 2px;
  }

  @media (max-width: $break-small) {
    input[type=date] {
      width: 150px;
      padding-right: 5px;
    }
  }
  input[type=date] {
    width: 150px;
  }
  input[type=submit] {
    margin-left: 7px;
  }

  .ico_cross {
    width: 11px;
    height: 11px;
    vertical-align: middle;
  }

  .IcoDone {
    width: 13px;
    height: 10px;
    vertical-align: middle;
  }

  &__InputError,
  &__SearchError {
    color: $color-red;
    font-size: 1.3rem;
    margin: 7px 0px;
  }

  &__SearchProgress {
    margin: 7px 0px;
    font-style: italic;
  }

  &__SearchResult {
    margin: 7px 0px;

    .success {
      padding: 20px 0px 0px;
    }
  }
}