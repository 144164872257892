.Avatar {
  position: relative;
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 60px;

  &__Missing {
    width: 60px;
    height: 60px;
    border: 1px solid $color-grey;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }

  .Snowflake {
    position: absolute;
    width: 33px;
    top: 33px;
    left: 33px
  }

  .IcoCamera {
    width: 25px;
    fill: none;
    stroke: white;
    position: absolute;
    right: 5px;
    bottom: 1px;
    pointer-events: none;
  }

  &:hover {
    .IcoCamera {
      fill: rgba(255, 255, 255, 0.35);
    }
  }
}
