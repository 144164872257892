.Partners {
  padding: 30px;

  &__Logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 5%;
    row-gap: 30px;

    h3 {
      flex: 1 0 130px;
    }

    > div {
      height: 130px;
      flex: 0 0 130px;
      text-align: center;

      &:first-of-type {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      a, span {
        width: 130px;
        height: 130px;
        line-height: 130px;
        display: inline-block;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
      }
    }

    &--secondary {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $color-grey-light;

      > div {
        height: 95px;
        flex: 0 0 95px;

        span {
          padding: 5px;
        }

        a, span {
          width: 95px;
          height: 95px;
          line-height: 85px;
          //box-sizing: content-box;
        }
      }
    }

    @media (max-width: $break-small) {
      > div {
        &:first-of-type {
          text-align: center;
        }

        &:last-child {
          text-align: center;
        }
      }
    }
  }
}
