.Trophies {
  position: relative;
  margin-bottom: 20px;

  .box {
    padding: 30px;
  }

  &__Scroll {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 60px;
    overflow-x: auto;

    // this hell is here because browser are hiding horizontal scollbars until some action is done
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }
  }

  .Trophy {
    padding: 15px 0;
    text-align: center;
    align-items: center;
    align-self: end;
    flex: 0 0 auto;

    h4 {
      margin-bottom: 25px;
    }

    &__Cont {
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
    }

    &__Image {
      filter: drop-shadow(0 2px 8px rgba(16, 13, 16, 0.25));
      -webkit-filter: drop-shadow(0 2px 8px rgba(16, 13, 16, 0.25));

      img {
        height: 150px;
        opacity: 0.5;
        filter: grayscale(0.65);
      }
    }

    &--completed {
      .Trophy__Image {
        img {
          opacity: 1;
          filter: none;
        }
      }

      h4 {
        margin-bottom: 5px;
      }
    }

    &--activeSeason {
      cursor: pointer;

      .Trophy__Image:hover {
        transform: scale(1.07);
        filter: drop-shadow(0 2px 8px rgba(16, 13, 16, 0.35));
        -webkit-filter: drop-shadow(0 2px 8px rgba(16, 13, 16, 0.35));
      }
    }

    .DiplomaPdfDownload {
      font-size: 1.3rem;

      .IcoDiploma {
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .Trophy__Progress {
    display: flex;
    flex-direction: column-reverse;

    &__Item {
      .IcoDone {
        width: 22px;
        height: 13px;
        fill: transparent;
        stroke: $color-grey-dark;
      }

      &--done {
        .IcoDone {
          fill: $color-green;
          stroke: none;
        }
      }
    }
  }

  @media (max-width: $break-small) {
    .box {
      padding: 15px;
    }

    &__Scroll {
      overflow-x: unset;
      flex-direction: column;
      flex-wrap: unset;
      gap: 30px;
    }

    .Trophy {
      align-self: center;
      flex: 1 1 20%;
    }

  }
}