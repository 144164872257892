
.AutoConnector {
  margin-top: 30px;
  border-top: 1px solid $color-grey;

  &__Headline {
    font-weight: bold;
    margin: 0px 0 30px;
  }

  &__Response {
    .IcoDone {
      width: 15px;
      vertical-align: bottom;
    }
  }

  &__ButtonCont {
    margin-top: 30px;
    text-align: right;
  }
}