
.ContactPage {
  line-height: 1.3;

  h1 {
    margin-bottom: 30px;
  }
  h2 {
    margin: 0 0 7px;
  }
  h3 {
    margin-bottom: 30px;
  }

  ul {
    list-style: disc;
  }
  li {
    padding-bottom: 10px;
  }

  .label {
    font-weight: bold;
    color: $color-grey-dark;
  }

  .row {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;
  }
  @media (max-width: $break-small) {
    .row {
      grid-template-columns: 100%;
    }
  }

  &__Address {
    padding: 30px 30px 45px;
    margin-bottom: 15px;
  }

  &__Form {
    padding: 30px 30px 45px;
  }

  .ContactForm {
    position: relative;
    padding: 15px;
    display: block;
    background-color: #c0dced;
    box-shadow: 1px 1px 1px 0px #a4b3bb inset;
    border-right: 1px solid white;
    border-bottom: 1px solid white;

    label {
      display: block;
    }
    textarea {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    input[type=text] {
      width: 100%;
      margin-bottom: 10px;
    }

    .Snowflake {
      position: absolute;
      //width: 33px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__Message {
      display: none;
    }
    ipnut[name=message], input[name=subject] {
      visibility: hidden;
    }

    &__ButtonCont {
      text-align: right;
      padding-top: 15px;

      .Button {
        min-width: 100px;
      }
    }
  }
}
