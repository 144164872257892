.DashboardTile {
  @media (max-width: $break-small) {
    display: block;
  }

  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 5px;

  &__Frame {
    position: relative;
    padding: 30px 45px 90px;
    min-height: 260px;
    border: 1px solid $color-grey;
    //display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    text-align: center;

    @media (max-width: $break-small) {
      padding: 30px 30px 90px;
    }

    &__Image {
      float: left;
      margin-right: 30px;
      height: 100px;
      margin-bottom: 15px;
    }

    .lineWithIco{
      text-align: left;
    }

    &__ButtonCont {
      clear: both;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);

      .Button {
        width: 120px;
      }
    }
  }

  &.ConnectTile {
    @media (max-width: $break-small) {
      margin-bottom: 15px;
    }
  }
}
