
@import "~normalize-scss/sass/normalize";
@import "variables";

@font-face {
  font-family: "JosefinSans";
  src: url("font/Josefin_Sans/JosefinSans-Regular.ttf");
}
@font-face {
  font-family: "JosefinSans";
  src: url("font/Josefin_Sans/JosefinSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "JosefinSans";
  src: url("font/Josefin_Sans/JosefinSans-Italic.ttf");
  font-style: italic;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  line-height: 1.1;
}

body,
input,
textarea,
select,
button {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';

  font-family: JosefinSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: #FFFFFF;
  margin: 0;
}

a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

p {
  margin: 5px 0 10px 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

* {
  box-sizing: border-box;
  border-collapse: collapse;
}

body {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("assets/Dachstein-Gletscher.jpg"), linear-gradient(to top, #17447F 100%, #688FC4 100%); /* W3C */
}

// layout

.clear {
  clear: both;
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

.error {
  color: $color-error;
}
.success {
  color: $color-green;
}
.pink {
  color: $color-pink;
}
.disabled {
  filter: grayscale(1);
}

.mt6 {
  margin-top: 6px;
}
.mt15 {
  margin-top: 15px;
}
.mt30 {
  margin-top: 30px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb30 {
  margin-bottom: 30px;
}
.padding30 {
  padding: 30px;
}

.box {
  background-color: $color-bg-grey;
  //border: 1px solid #CED1D9;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
}

.IcoCross {
  width: 30px;
  height: 30px;
  fill: #bc3535;
}
.IcoDone {
  width: 40px;
  height: 20px;
  fill: #139b13;
}
.IcoError {
  width: 1.3rem;
  height: 1.3rem;
  fill: transparent;

  ellipse {
    stroke: white;
    stroke-width: 2;
  }
  text {
    fill: white;
  }
}
.IcoHome {
  stroke: none;
  fill: white;
  width: 40px;
  height: 40px;
}
.IcoCamera {
  stroke: none;
  fill: $color-blue;
  width: 40px;
  height: 31px;
}
.IcoMenu {
  width: 30px;
  height: 30px;
  stroke: white;
  stroke-width: 5;
  stroke-linecap: square;
  fill: none;
}

.Snowflake {
  stroke: none;
  fill: white;
  width: 45px;
  height: 50px;

  &--anim {
    animation: Snowflake infinite 2.5s ease-out, ColorsSvg infinite 2.5s linear;
  }

  &--blue {
    fill: $color-blue;
  }
  &--red {
    fill: $color-red;
  }
  &--green {
    fill: $color-green;
  }
}

.App {
  width: 100%;
  height: 100vh;
  position: relative;
}

.logo {
  position: absolute;
  top: 15px;
  left: 15px;
  height: 102px;

  @media (max-width: $break-notebook) {
    height: 85px;
  }

  @media (max-width: $break-small) {
    top: 2vw;
    right: 2%;
    //top: unset;
    left: unset;
    height: 38px;
    position: relative;
    float: right;
  }
}

.MyLogo {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-style: italic;
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: $break-notebook) {
    font-size: 1.7rem;
  }

  @media (max-width: $break-small) {
    display: none;
  }
}

.Page {
  height: 100%;

  &__Container {
    width: $b-regular;
    margin: 0 auto;

    [class$=Page] {
      padding-bottom: 30px;
    }
  }

  &--simple {
    .Page__Container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .box {
        height: 333px;
        width: 450px;
        padding: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

input[type=text], input[type=number], input[type=email], input[type=password], input[type=date], input[type=tel], select, textarea {
  display: inline-block;
  vertical-align: middle;
  appearance: none;
  line-height: 1.4;
  padding: 8px 12px;
  font-size: 1.4rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  color: $color-black;
  border: 1px solid $color-grey;
  background: $color-bg-ipnut;
  border-radius: 1px;
  transition: box-shadow $a-speed;
  background-clip: padding-box;

  &[type=search]::-webkit-search-cancel-button {
    display: none;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder{
    color: $color-grey;
    opacity: 1;
    font-style: italic;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 3px 2px rgba($color-blue, 0.3);
    border-color: $color-blue;
  }

  &.error {
    border-width: 1px;
    border-color: $color-red;
    border-style: solid;

    &:focus {
      box-shadow: 0 0 3px 2px rgba($color-red, 0.3);
    }

    + p.error {
      color: $color-red;
    }
  }

  &:disabled {
    opacity: 0.55;
  }

  &.small {
    padding: 5px 10px;
  }
}

select, input[type=date]{
  appearance: auto;
  padding: 8px 12px 7px;

  &.small {
    padding: 5px 10px 3px; // toto neni vyzkousene / pouzite
  }
}

input[type-date] {
  &.empty::-webkit-datetime-edit-text,
  &.empty::-webkit-datetime-edit-month-field,
  &.empty::-webkit-datetime-edit-day-field,
  &.empty::-webkit-datetime-edit-year-field {
    color: $color-grey !important;
  }
}

.ImageUpload {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: $break-small) {
  .Page__Container {
    width: 96%;
    //overflow-y: auto;
  }
}
@media (min-width: $break-small) {
  .Page__Container {
    width: $b-small;
  }
}
@media (min-width: $break-medium) {
  .Page__Container {
    width: $layout-width-smaller;
  }
}
@media (min-width: $break-notebook) {
  .Page__Container {
    width: $b-regular;
  }
}
@media (min-width: 1200px) {
}

@import "pages/LoginPage";
@import "pages/DashboardPage";
@import "pages/UserEditPage";
@import "pages/OrderPage";
@import "pages/OrderDonePage";
@import "pages/ContactPage";
@import "pages/SignInLandingPage";
@import "pages/RacerActionPage";

@import "components/Avatar";
@import "components/Button";
@import "components/Overlay";
@import "components/Topmenu";
@import "components/FlashMessage";
@import "components/BarMessage";
@import "components/PassportConnector";
@import "components/DashboardTile";
@import "components/BuyTile";
@import "components/AccountBox";
@import "components/UserForm";
@import "components/AutoConnector";
@import "components/Passport";
@import "components/PassportNone";
@import "components/PassportDetail";
@import "components/UserResults";
@import "components/Trophies";
@import "components/AccountConnector";
@import "components/Loader";
@import "components/LoginToken";
@import "components/Partners";

@media (prefers-reduced-motion: no-preference) {
  // pouze pro device, kde neni omezena animace
}

@keyframes Snowflake {
  0% {
    transform: rotate3d(0, 0, 0, 0);
  }
  24% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
  25% {
    transform: rotate3d(-1, -1, 1, 0deg);
  }
  50% {
    transform: rotate3d(-1, -1, 1, -360deg);
  }
  51% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);

  }
}

@keyframes ColorsSvg {
  0% {
    fill: white;
  }
  33% {
    fill: $color-blue;
  }
  66% {
    fill: $color-green;
  }
  100% {
    fill: white;
  }
}

@keyframes Spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}