.UserResults {
  position: relative;

  .box {
    padding: 30px;
  }

  table {
    width: 100%;

    th {
      padding: 4px 10px;
      text-align: left;
      background-color: $color-grey;
      line-height: inherit;
    }

    td {
      padding: 4px 10px;
      line-height: inherit;

      .label {
        display: none;
      }
    }

    .catPos {
      font-weight: bold;
      width: 90px;
    }

    .catPos, .genderPos {
      text-align: center;
    }

    tbody tr:nth-child(even) {
      background-color: $color-bg-grey-light;
    }
    tbody tr:nth-child(odd) {
      background-color: $color-bg-grey-little-darker;
    }
  }

  @media (max-width: $break-small) {
    .box {
      padding: 15px;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 15px 7px;
          margin-bottom: 7px;
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
        }

        td {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;

          .label {
            display: block;
            color: $color-grey;
            font-size: 1.3rem !important;
            font-weight: normal !important;
          }
        }

        .season {
          font-weight: bold;
          flex-basis: 25%;
          justify-content: center;
        }
        .raceName {
          font-size: 1.8rem;
          font-weight: bold;
          flex-basis: 75%;
        }
        .time {
          flex-basis: 40%;
          padding: 5px 10px;
        }
        .event {
          flex-basis: 60%;
          padding: 5px 10px;
        }
        .overalPos {
          font-size: 1.8rem;
          font-weight: bold;
          flex-basis: 40%;
        }
        .category {
          flex-basis: 30%;
        }
        .catPos {
          font-weight: bold;
          flex-basis: 30%;
          width: unset;
        }

      }
    }
  }
}