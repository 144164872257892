.BuyTile {
  ul {
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: left;

    @media (max-width: $break-small) {
      margin-top: 0px;
      margin-block-start: 0;
    }
  }
  li {
    padding: 7px 0 10px;
  }

  @media (max-width: $break-small) {
    .DashboardTile__Frame__Image {
      margin-top: 5px;
    }
  }
}