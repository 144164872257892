
.FlashMessage {
  z-index: 10;

  .Overlay__Panel {
    overflow-y: auto;
    min-height: unset;
  }

  .InfoMessage {
    text-align: left;
    max-width: 640px;
    margin: 0 auto;
    padding-top: 30px;

    svg {
      fill: #1f79e1;
     // stroke: #1f79e1;
      //stroke-width: 1;
      height: 60px;
      width: 60px;

      position: absolute;
      top: 3rem;
      left: 6rem;
    }
  }

  &--success {
    .Overlay__Panel {
      min-width: auto;
      min-height: auto;
      background-color: $color-green;
      color: white;

      .CloseButton {
        border-color: white;
        svg {
          fill: #fff;
        }
      }
    }
  }
  &--error {
    .Overlay__Panel {
      min-width: auto;
      min-height: auto;
      background-color: $color-red;
      color: white;

      .CloseButton {
        border-color: white;
        svg {
          fill: #fff;
        }
      }
    }
  }

  &--success.Overlay--minimized,
  &--error.Overlay--minimized {
    .Overlay__Panel {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
