.Passport {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 15px;

  @media (min-width: $b-small) {
    display: flex;
  }

  &__Properties {
    flex-basis: 50%;
    position: relative;

    &__Image {
      position: relative;
      display: inline-block;
      left: -60px;
      z-index: 3;

      > img {
        height: 200px;
        cursor: pointer;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.5));
      }

      @media (min-width: $break-notebook) {
        left: -35px;
      }
    }

    &__Values {
      float: left;
      z-index: 2;
      position: relative;
      margin: 25px 0 0 10px;
      padding: 15px 55px 15px 40px;
      min-width: 230px;
      height: 148px;
      background-color: white;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .16), 0 1px 18px 0 rgba(0, 0, 0, .08), 0 6px 10px 0 rgba(0, 0, 0, .10);

      .label {
        color: $color-grey;
        font-weight: normal;
      }

      .number {
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 15px;
      }

      .expiration {
        .date {
          font-weight: bold;
        }
        &.active {
          color: $color-green;
        }
        &.expired {
          color: $color-red;
        }
      }
    }

    &__History {
      width: 150px;
      height: 148px;
      position: absolute;
      top: 25px;
      right: 15px;

      &__Trigger {
        padding: 10px;
        width: 100%;
        background-color: white;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .16), 0 1px 18px 0 rgba(0, 0, 0, .08), 0 6px 10px 0 rgba(0, 0, 0, .10);
        display: inline-block;
        position: absolute;
        right: 0;
        text-align: right;
        font-size: 1.3rem;
        font-weight: bold;
        cursor: pointer;

        &--desktop {
          margin-left: 7px;
        }
        &--mobile {
          display: none;
        }
      }
      &__Content {
        position: absolute;
        width: 100%;
        height: 100px;
        overflow-y: auto;
        z-index: 1;
        left: -160px;
        opacity: 0;
        bottom: 1px;
        transition: left ease-out $a-speed, opacity ease-out $a-speed;
        padding: 10px 10px 10px 30px;
        background-color: white;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .16), 0 1px 18px 0 rgba(0, 0, 0, .08), 0 6px 10px 0 rgba(0, 0, 0, .10);
        font-size: 1.3rem;
        font-weight: bold;
      }

      &--open {
        .Passport__Properties__History__Content {
          left: 0px;
          opacity: 1;
        }
      }
    }

    @media (max-width: $break-medium) {
      flex-basis: 56%;

      &__Values {
        margin: 0;
        margin-bottom: 15px;
        padding: 15px 55px 15px 30px;
      }

      &__Image {
        display: block;
        left: unset;
        float: right;
        position: absolute;
        top: 30px;
        right: 0px;

        @media (min-width: $b-small) {
          right: 45px;
        }

      }

      &__History {
        position: relative;
        right: -20px;
        top: unset;
        clear: left;

        &__Trigger {
          right: unset;
          left: 0;
          text-align: left;
        }

        &__Trigger--desktop {
          display: none;
        }
        &__Trigger--mobile {
          display: inline-block;
          margin-right: 7px;
        }

        &__Content {
          left: unset;
          top: -100px;
          height: max-content;
          transition: top ease-out $a-speed, opacity ease-out $a-speed;
        }
        &--open {
          .Passport__Properties__History__Content {
            left: unset;
            top: 40px;
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: $b-small-max) {
      padding: 10px;
      margin-bottom: 60px;



      &__Values {
        float: none;
        margin: 0;
        margin-bottom: 15px;
        padding: 15px 55px 15px 30px;

        .number {
          letter-spacing: -0.5px;
        }
      }
    }

    @media (max-width: $break-tiny) {
      padding: 0;

      &__Image {
        right: -10px;

        > img {
          height: 160px;
        }
      }

      &__Values {
        padding: 15px 20px;
      }

      &__History {
        right: -10px;
      }
    }

    &__Download {
      padding-left: 30px;

      .PassportPdfDownload {
        display: inline-block;
        text-align: center;
      }

      .Button {
        color: black;
      }

      .ButtonLink {
        display: inline-block;
        margin-top: 5px;
        font-size: 1.3rem;
      }

      .LoadingText {
        margin-left: 10px;
        font-size: 1.3rem;
        font-style: italic;
      }



      @media (max-width: $break-medium) {
        padding-left: unset;
        text-align: right;
        float: right;
        position: relative;
        top: -50px;
        right: 20px;
      }
      @media (min-width: $b-small-max) and (max-width: $break-medium) {
        right: 45px;
      }
    }
  }

  &__PassportConnect {
    flex-basis: 60%;
    padding-right: 30px;
  }
}
