
.OrderPage {
  padding-bottom: 15px;

  .ProfileSection {
    position: relative;
    margin-bottom: 30px;
  }

  .OrderSection {
    .box {
      padding: 30px;

      @media (max-width: $break-small) {
        padding: 20px;
      }
    }

    .label {
      color: $color-grey;
      display: inline-block;
      margin-right: 7px;
    }

    .Step {
      margin-bottom: 15px;

      h3 {
        position: relative;
        margin: 0;
        padding: 15px;
        padding-left: 0;
        border-bottom: 1px solid #d2d9dd;

        .ButtonLink  {
          //position: absolute;
          //right: 15px;
          margin-left: 6px;
          //line-height: 25px;
          font-size: 1.5rem;
        }
      }

      table {
        text-align: left;
        th {
          vertical-align: top;
          padding: 4px 15px 4px 0px;
          font-weight: normal;
        }
        td {
          font-weight: bold;
          padding: 4px 0px;
        }
      }

      &__Content {
        padding: 30px;
        display: none;
      }

      &__Recap {
        border: 1px solid #d2d9dd;
        padding: 30px;
        display: flex;
        flex-wrap: wrap;

        &__Block {
          flex: 1;
          th {
            min-width: 110px;
          }
        }

        @media (max-width: $break-small) {
          padding: 20px;

          table {
            td {
              padding: 7px 0;
            }
            th {
              padding: 7px 15px 7px 0px;
            }
          }
        }
      }

      .IcoDone {
        width: 26px;
        height: 13px;
        margin-left: 7px;
      }

      &--active {
        h3 {
          background-color: inherit;
          border-bottom: none;
        }

        .Step__Content {
          display: block;
          background-color: #c0dced;
          box-shadow: 1px 1px 1px 0px #a4b3bb inset;
          border-right: 1px solid white;
          border-bottom: 1px solid white;
        }

        &.Step--Confirmation {
          .Step__Content {
            display: flex;
          }
        }
      }

      &--UserDetails {
      }

      &--Confirmation {
        .Step__Content {
          flex-direction: row;
          flex-wrap: wrap;

          &__Block {
            flex: 1 1 75%;

            &--price {
              flex-basis: 20%;

              @media (max-width: $break-small) {
                margin-top: 25px;
              }
            }
          }
        }

        .subject, .validity {
          font-size: 2.2rem;
        }

        .price {
          font-size: 4rem;
          font-weight: bold;
        }

        .submit {
          margin-top: 3em;

          @media (max-width: $break-small) {
            margin-top: 30px;
          }

          .Button--success {
            width: 100%;
          }
        }

        td, th {
          padding: 5px 0px;
        }

        .Step__Recap .IcoDone {
          width: 20px;
          height: 10px;
          margin-right: 7px;
          margin-left: 0;
        }

      }

      &--Payment {
        padding: 30px 0;

        p {
          text-align: center;
          background-color: inherit;
        }
      }
    }

  }
}
