.AccountConnector {

  .IcoCross {
    width: 15px;
  }
  .IcoDone {
    width: 18px;
  }

  &__Content {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  &__PassportNumber {
    &__Input {
      display: flex;

      input[type=text] {
        flex: 1;
        margin-bottom: 0;
        width: auto;
      }

      .Button {
        flex: 0 0 auto;
        margin: 0;
        width: auto;

        .IcoDone {
          fill: white;
        }
      }
    }

    &__Info {
      line-height: 38px;
      display: block;
      vertical-align: middle;

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      &__Response {
        vertical-align: top;
      }

      .IcoCross {
        vertical-align: middle;
        margin-right: 5px;
      }
      .IcoDone {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  &__SearchResult {

    input[type=date] {
      width: 100%;
    }
    &__Label {
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 5px;
      color: $color-grey-dark;
    }

    &__Info,
    &__Response {
      line-height: 38px;
      display: block;
      vertical-align: middle;

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .IcoCross, .IcoDone {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    &__Response {
    }
  }

  @media (min-width: $break-small) {
    &__PassportNumber {
      &__Info {

      }
    }

    &__SearchResult {
      &__Info {

      }
    }
  }

  @media (max-width: $break-tiny) {

  }
}