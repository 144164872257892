.Topmenu {
  position: relative;
  padding: 15px 5px 15px;
  overflow: auto;
  color: white;

  .Page__Container {
    height: auto;
    padding-top: 0;
  }

  &__IconNav {
    float: left;
    margin-right: 15px;

    .IcoMenu {
      display: none;
    }
  }

  &__Email {
    display: none;
    color: $color-grey-light;

  }

  &__Item {
    position: relative;
    display: inline-block;
    padding: 8px 16px 0px;
    vertical-align: middle;
    line-height: 22px;
    font-weight: bold;
    color: white;
    text-decoration: none;

    span {
      position: relative;
      display: inline-block;
      padding: 0 15px 8px;
      border-bottom: 5px solid transparent;
      filter: drop-shadow( 1px 1px 10px rgba(0, 0, 0, .75));
    }

    &:hover {
      text-decoration: none;

      span {
        top: 2px;
      }
    }

    &--active {
      span {
        border-bottom-color: $color-blue;
      }

      &:hover {
        span {
          top: 0px;
        }
      }
    }

    &::after {
      content: '|';
      font-weight: normal;
      position: absolute;
      right: 0;
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }

  &__Rightmenu {
    float: right;

    > * {
      float: right;
    }

    &__Email {
      color: $color-grey-light;
      margin-right: 15px;
      line-height: 38px;
      vertical-align: middle;
    }
  }

  .IcoHome {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }

  @media (max-width: $break-small) {
    z-index: 10;
    padding: 3vw 2vw;

    .Page__Container {
      width: 100%;
    }

    &__Email {
      display: block;
      font-size: 1.4rem;
      line-height: 30px;
      vertical-align: middle;
    }

    &__Content {
      display: none;

      &--opened {
        display: block;
        padding: 10px 0;
      }
    }

    &__Rightmenu {
      float: none;
      margin-top: 5px;

      > * {
        float: none;
      }

      &__Email {
        display: none;
      }
    }

    .IcoMenu {
      display: block;
    }

    .Topmenu__Item {
      display: block;
      padding: 12px 0px 0px;

      span {
        display: block;
        padding-left: 0;
        padding-bottom: 12px;
      }

      &::after {
        content: none;
      }

      &--active {
        border-bottom-color: $color-blue;
        background-color: transparentize($color-blue, 0.75);

        span {
          padding-left: 7px;
        }

        &:hover {
          span {
            top: 0px;
          }
        }
      }
    }
  }
}
