
.RacerActionPage {
  h1 {
    margin-bottom: 45px;
  }
  h2 {
    margin: 0 0 7px;
  }

  > .box {
    padding: 30px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width: $break-medium) {

    }
    @media (max-width: $break-smallest) {
      padding: 15px;
      padding-bottom: 30px;
    }
  }

  &__Logo {
    font-size: 36px;
    color: #bc3535;
    font-weight: bold;
    margin-bottom: 45px;
  }

  &__Content {

    &::after {
      position: absolute;
      top: 75px;
      right: 100px;
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.2;
      z-index: -1;
      background-image: url('../assets/cup1.png');
      background-position: right top;
      background-repeat: no-repeat;
      background-size: 22%;

      @media (max-width: $break-medium) {
        right: 45px;
      }

      @media (max-width: $break-small) {
        top: 55px;
        right: 55px;
        background-size: 20%;

      }
    }

    &__Races {
      h3 {
        margin-bottom: 30px;
      }
      table {
        width: 100%;

        tbody {
          background-color: $color-bg-grey-little-darker;

          &:nth-child(2n+1) {
            background-color: $color-bg-grey-light;
          }
        }
        td {
          padding: 7px 15px;
          line-height: 38px;
        }
      }

      &__Race {
        &--notAllowed {
          color: $color-grey;
        }

        &__Action {
          text-align: right;
        }
      }

      @media (max-width: $break-small) {
        table {
          tbody {
            tr {
              display: block
            }
            td {
              display: block;
            }
          }
        }

        &__Race {
          &__Title {
            padding: 0 15px !important;
          }
        }
      }
    }

    &__Voucher {
      margin-top: 35px;
      clear: both;

      h3 {
        margin-bottom: 10px;
        font-weight: normal;
      }

      &__Detail {
        position: relative;
        background-image: url('../assets/racer_voucher_800x400.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: white;
        width: 600px;
        height: 300px;
        margin-top: 15px;

        &__User {
          position: absolute;
          top: 109px;
          left: 50px;
          font-size: 2rem;
          font-weight: bold;
          line-height: 1.3;

          &__Dob {
            margin-top: 35px;
          }

          &__Passport,
          &__Dob {
            font-size: 1.5rem;

            &__Label {
              font-weight: normal;
              display: inline-block;
              margin-right: 10px;
            }
          }

          &__Address {
            font-size: 80%;
          }
        }

        &__Code {
          position: absolute;
          bottom: 65px;
          right: 100px;
          font-size: 2.5rem;
          font-weight: bold;
        }
        &__Race {
          position: absolute;
          top: 55px;
          left: 25px;
          font-size: 2.5rem;
          font-weight: bold;
          width: calc(100% - 25px);

          &__Date {
            display: inline-block;
            position: absolute;
            right: 25px;
            top: 3px;
            font-size: 2rem;
          }
        }
      }

      .hidden-sm {
        display: initial;
      }
      .hidden-lg {
        display: none;
      }

      @media(max-width: $break-smaller) {
        &__Detail {
          width: 100%;
          height: unset;
          padding: 25%;

          &__User {
            top: 34%;
            left: 30px;
            font-size: 1.2rem;
            line-height: 1.2rem;

            &__Dob {
              margin-top: 15px;
            }

            &__Passport,
            &__Dob {
              font-size: 1.1rem;
            }
          }

          &__Code {
            bottom: 22%;
            right: unset;
            left: 65%;
            font-size: 1.2rem;
          }

          &__Race {
            top: 20%;
            left: 10px;
            font-size: 1.1rem;
            width: calc(100% - 10px);

            &__Date {
              top: unset;
              right: 10px;
              font-size: 1.1rem;
              font-weight: normal;
            }
          }
        }

        .hidden-sm {
          display: none;
        }
        .hidden-lg {
          display: initial;
        }
      }
    }

    .VoucherPdfDownload {
      margin-top: 30px;
      font-size: 1.5rem;

      .Button {
        &:link,
        &:visited {
          color: black;
        }
      }

      .IcoDiploma {
        margin-right: 10px;
        vertical-align: middle;
      }

      .LoadingText {
        margin-left: 10px;
        line-height: 38px;
        font-style: italic;
      }
    }
  }


}
