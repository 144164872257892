.PassportNone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: $break-small) {
    display: block;
  }

  &__Subtitle {
    flex-basis: 100%;
    font-size: 1.3rem;
  }

  &__PassportConnect {
    flex-basis: 50%;

    @media (max-width: $break-small) {
      flex-basis: 100%;
    }
  }
}
