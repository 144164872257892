.SignInLandingPage {
  h1 {
    color: white;
    font-size: 5.8rem;
    margin-bottom: 60px;
  }

  @media (max-width: $break-smaller) {
    .Page__Container {
      display: block;
    }

    h1 {
      font-size: 3.2rem;
      margin-bottom: 10px;
      margin-top: 0;
      padding-top: 15px;
    }

    .box {
      width: 100%;
      flex-basis: 300px;
    }
  }

  @media (max-width: $break-tiny) {
    h1 {
      font-size: 2rem;
      padding-right: 60px;
    }

    .box {
      padding: 25px 15px 45px;
    }
  }


  input[type=text], input[type=email], input[type=password] {
    width: 100%;
    height: 38px;
    line-height: 28px;
    display: block;
  }

  .Button {
    width: 100%;
    margin: 20px 0 15px;
    display: block;
  }
}